import React from 'react';

const ProgressBar = () => {
    return (
        <div>
            <h1>Generating playlist...</h1>
        </div>
    );
}

export default ProgressBar;
